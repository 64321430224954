import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import { HealthcareProviderResponse } from 'api/models/HealthcareProviders';
import { ConnectHealthRecordsIllustration } from 'assets/illustrations';
import { useConfig } from 'hooks/useConfig';
import { buildEhrAuthUrl } from 'helpers/links';
import {
  Container,
  Card,
  StyledReconnectIcon,
  StyledCloseIcon,
  CloseIconWrapper,
  BackgroundImageWrapper,
  EhrLogo,
  EhrTitle,
  WarningIndicator,
  ReconnectButton,
} from './styled';
import { messages } from './messages';

interface Props {
  show: boolean;
  healthProvider?: HealthcareProviderResponse;
  onClose: () => void;
  displaySmallerCard?: boolean;
}

export const ReconnectHealthProviderCard = forwardRef<HTMLDivElement, Props>(
  ({ show, healthProvider, onClose, displaySmallerCard = false }, ref) => {
    const { t } = useTranslation();
    const { ehrRedirectUri } = useConfig();

    if (!healthProvider) return null;
    return (
      <Container show={show} ref={ref} aria-hidden={show}>
        <Card>
          {displaySmallerCard && (
            <BackgroundImageWrapper>
              <ConnectHealthRecordsIllustration />
            </BackgroundImageWrapper>
          )}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            marginBottom="1.6rem"
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing="0.8rem"
              color="additionalColors.accentError"
            >
              <WarningIndicator />
              <Typography variant="xxs" color="additionalColors.accentError">
                {t(messages.disconnected())}
              </Typography>
            </Stack>
            <CloseIconWrapper onClick={onClose} disableRipple>
              <StyledCloseIcon />
            </CloseIconWrapper>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={2}>
            {!displaySmallerCard && (
              <EhrLogo
                src={healthProvider.iconPath}
                alt={'logo of the health provider'}
              />
            )}
            <EhrTitle variant="h5" $displaySmallerCard={displaySmallerCard}>
              {healthProvider.name}
            </EhrTitle>
          </Stack>
          <Typography variant="body1">{t(messages.description())}</Typography>
          <ReconnectButton
            target="_self"
            href={buildEhrAuthUrl({
              webAuthUrl: healthProvider.ehr.auth.configuration.webAuthUrl,
              providerId: healthProvider.id,
              redirectUri: ehrRedirectUri,
            })}
            data-cy="connect-ehr-account"
          >
            <StyledReconnectIcon />
            {t(messages.reconnectAccount())}
          </ReconnectButton>
        </Card>
      </Container>
    );
  },
);
