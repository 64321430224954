export const buildEhrAuthUrl = ({
  webAuthUrl,
  providerId,
  redirectUri,
}: {
  webAuthUrl: string;
  providerId: string;
  redirectUri: string;
}) => {
  const parsedUrl = new URL(webAuthUrl);
  parsedUrl.searchParams.set('redirect_uri', redirectUri);
  // Inject providerId as state query param
  parsedUrl.searchParams.set('state', providerId);
  return parsedUrl.toString();
};
