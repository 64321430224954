import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Add } from 'iconsax-react';
import { Button, Stack, Typography } from '@mui/material';
import { HealthcareProviderResponse } from 'api/models/HealthcareProviders';
import { useConfig } from 'hooks/useConfig';
import { useClearLocationState } from 'hooks/useClearLocationState';
import { Modal } from 'components/Modal';
import { ModalContent } from 'components/Modal/ModalContent';
import { buildEhrAuthUrl } from 'helpers/links';
import { ProviderRadio } from './ProviderRadio';
import { messages } from './messages';
import { Params, RedirectActions } from '../../types';

interface Props {
  healthcareProviders: HealthcareProviderResponse[];
}

export const ConnectNewAccount = memo(({ healthcareProviders }: Props) => {
  const { t } = useTranslation();
  const { ehrRedirectUri } = useConfig();
  const location = useLocation();
  const clearLocationState = useClearLocationState();
  const { redirectAction } = (location.state || {}) as Params;
  const [showModal, setShowModal] = useState(
    redirectAction === RedirectActions.CONNECT_PROVIDER,
  );
  const [selectedHealthcareProvider, setSelectedHealthcareProvider] =
    useState<HealthcareProviderResponse | null>(null);

  useEffect(() => {
    if (redirectAction === RedirectActions.CONNECT_PROVIDER) {
      setShowModal(true);
      clearLocationState();
    }
  }, [redirectAction]);

  useEffect(() => {
    setSelectedHealthcareProvider(null);
  }, [showModal]);

  const onSubmit = () => {
    if (!selectedHealthcareProvider) return;
    window.location.href = buildEhrAuthUrl({
      webAuthUrl: selectedHealthcareProvider.ehr.auth.configuration.webAuthUrl,
      providerId: selectedHealthcareProvider.id,
      redirectUri: ehrRedirectUri,
    });
  };

  return (
    <>
      <Button variant="text" onClick={() => setShowModal(x => !x)}>
        <Add />
        {t(messages.connectNewAccount())}
      </Button>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        title={t(messages.connectYouPatientPortalAccount())}
      >
        <ModalContent style={{ marginTop: '2.4rem' }}>
          <Typography variant="body1" color="additionalColors.bodyText">
            {t(messages.moreHealthcareProvidersComingSoon())}
          </Typography>
          <Stack gap="1.6rem" direction="column" marginTop="1.6rem">
            {healthcareProviders.map(provider => (
              <ProviderRadio
                key={provider.id}
                checked={provider.id === selectedHealthcareProvider?.id}
                onClick={() => {
                  setSelectedHealthcareProvider(provider);
                }}
                {...provider}
              />
            ))}
          </Stack>
          <Stack
            gap="2.4rem"
            marginTop={{
              xs: 'auto',
              md: '3.2rem',
            }}
            direction={{
              md: 'row-reverse',
              xs: 'column',
            }}
          >
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ width: '100%' }}
              onClick={onSubmit}
              disabled={!selectedHealthcareProvider}
            >
              {t(messages.connectAccount())}
            </Button>
            <Button
              onClick={() => setShowModal(false)}
              variant="alternative"
              style={{ width: '100%' }}
            >
              {t(messages.cancel())}
            </Button>
          </Stack>
        </ModalContent>
      </Modal>
    </>
  );
});
